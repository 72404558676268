<template>
  <div class="equipment">
    <!-- 团队总介绍 -->
    <div
      class="equipment-introduce"
      v-for="(item, index) in recruitContent"
      :key="index"
    >
      <div class="card">
        <img src="../../../assets/images/n_zhaopin_ico.png" alt="" />
        <i></i>
        <div class="card-name">{{ item.title }}</div>
      </div>
      <div class="ei-word">
        <h5 class="ei-theme">{{ item.title }}</h5>
        <i></i>
        <div class="ei-text" v-html="item.richText"></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"; //引入axios
export default {
  name: "",
  data() {
    return {
      recruitContent: [],
    };
  },
  watch: {},
  created() {},
  mounted() {
    this.queryTitleId();
  },
  methods: {
    queryTitleId() {
      let lang = localStorage.getItem("lang") || "CN";
      axios
        .get("https://www.claybbt.com/queryTitleId", {
          params: {
            language: lang,
            titleId: this.$route.query.nodeId,
          },
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.recruitContent = res.data.data.pageDatas;
          } else {
            this.$message.error(data.msg);
          }
        });
    },
  },
  computed: {},
  filters: {},
};
</script>

<style lang="less" scoped>
.equipment {
  width: 100%;
  .equipment-introduce {
    min-height: 300px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 20px 0;
    .card {
      width: 221px;
      height: 250px;
      border-radius: 5px;
      border: 2px solid #d80930;
      color: #323339;
      cursor: pointer;
      margin-right: 100px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      padding: 40px 0;
      box-sizing: border-box;
      i {
        display: block;
        width: 18px;
        height: 4px;
        background: #d80930;
        margin-top: 16px;
        margin-bottom: 18px;
      }
      .card-name {
        font-size: 16px;
        color: #303030;
        font-weight: 600;
      }
    }
    .ei-word {
      width: 100%;
      padding: 50px;
      box-sizing: border-box;
      background: #f2f2f2;
      .ei-theme {
        font-size: 20px;
        color: #090909;
        font-weight: normal;
      }
      i {
        display: block;
        width: 18px;
        height: 4px;
        background: #d80930;
        margin-top: 16px;
        margin-bottom: 18px;
      }
      .ei-text {
        font-size: 15px;
        line-height: 36px;
        color: #4d4d4d;
      }
    }
  }
}
</style>
